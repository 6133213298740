class FileUploadStatusChangedEvent {
    constructor(upload) {
        this.upload = upload;
    }
}

class UploadCenter {
    constructor() {
        this.fileStore = new FileStore();
        this.initialize();

        const query = window.matchMedia('(max-width: 50em)');
        query.addEventListener(
            'change',
            (event) => {
                if (event.matches)
                    this.collapsed.setStatus(true);
            }
        );

        if (query.matches)
            this.collapsed.setStatus(true);
    }

    initialize() {
        this.title = document.createElement("span");
        this.title.className = "Title";

        this.toggleButton = document.createElement("button");
        this.toggleButton.classList = "Toggle";
        this.toggleButton.addEventListener("click", () => { this.toggle() });

        this.closeButton = document.createElement("button");
        this.closeButton.classList = "Close";
        this.closeButton.addEventListener("click", () => { this.close() });

        this.toolbar = document.createElement("span");
        this.toolbar.className = "Toolbar";
        this.toolbar.appendChild(this.title);
        this.toolbar.appendChild(this.toggleButton);
        this.toolbar.appendChild(this.closeButton);

        this.table = document.createElement("table");

        this.element = document.createElement("div");
        this.element.classList.add("UploadCenter");
        this.element.appendChild(this.toolbar);
        this.element.appendChild(this.table);

        this.collapsed = new HtmlClassSwitch(this.element, "Collapsed");

        const main = document.getElementById("main");
        main.appendChild(this.element);
    }

    createRow(upload) {
        const row = this.table.insertRow();
        row.className = State.toText(upload.state);
        row.dataset.Id = upload.id;

        let cell = row.insertCell();
        cell.classList.add("Icon");
        cell.appendChild(document.createElement("span"));

        cell = row.insertCell();
        cell.classList.add("Name");
        cell.appendChild(document.createTextNode(upload.name));

        cell = row.insertCell();
        cell.classList.add("Target");
        cell.appendChild(document.createTextNode(upload.target));

        cell = row.insertCell();
        cell.classList.add("Size")
        cell.appendChild(document.createTextNode((upload.size / 1000000).toFixed(2) + "MB"));

        const progress = document.createElement("span");
        progress.style.width = 100 * upload.progress + "%";

        const container = document.createElement("span");
        container.appendChild(progress);

        cell = row.insertCell();
        cell.classList.add("Progress");
        cell.appendChild(container);

        cell = row.insertCell();
        cell.classList.add("Actions");

        let action = document.createElement("button");
        action.className = "Abort";
        action.addEventListener("click", (event) => { this.abort(upload); });

        cell.appendChild(action);

        action = document.createElement("button");
        action.className = "Restart";
        action.addEventListener("click", (event) => { this.restart(upload); });

        cell.appendChild(action);

        const message = document.createElement("span");
        message.appendChild(document.createTextNode(upload.message));

        cell = row.insertCell();
        cell.classList.add("Message");
        cell.appendChild(message);
    }

    getRow(upload) {
        return new DomQuery(this.table).getDescendant((element) => { return element.dataset.Id == upload.id });
    }

    updateRow(row, upload) {
        row.childNodes[2].childNodes[0].innerText = upload.target;
        row.childNodes[4].childNodes[0].childNodes[0].style.width = 100 * upload.progress + "%";
        row.childNodes[6].childNodes[0].innerText = upload.message;
        row.className = State.toText(upload.state);
    }

    async abort(upload) {
        // TODO: This operation should happen in a single transaction.
        const file = await this.fileStore.get(upload.id);
        file.state = State.Aborted;

        await this.fileStore.update(file);
        this.serviceWorker.controller.postMessage(file);
    }

    async restart(upload) {
        // TODO: This operation should happen in a single transaction.
        const file = await this.fileStore.get(upload.id);

        if (file.location !== null) {
            file.state = State.Prepared;
            file.progress = 0.05;
            file.message = null;
        }
        else {
            file.state = State.Initial;
            file.progress = 0;
            file.message = null;
        }

        await this.fileStore.update(file);
        this.serviceWorker.controller.postMessage(file);
    }

    updateState() {
        if (this.isActive()) {
            this.element.classList.add("Active");
            this.element.classList.add("Expanded");
        }
        else
            this.element.classList.remove("Active");
    }

    isActive() {
        const query = new DomQuery(this.table);
        return this.table.rows.length > (query.getDescendants(WithClass("Successful")).length + query.getDescendants(WithClass("Aborted")).length);
    }

    close() {
        if (!this.isActive()) {
            while (this.table.rows.length > 0)
                this.table.deleteRow(-1);

            this.element.classList.remove("Expanded");
        }
    }

    toggle() {
        this.collapsed.toggle();
    }

    reload(upload) {
        let row = this.getRow(upload);

        if (row !== null) {
            row.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });

            this.updateRow(row, upload);
            this.updateTitle();

            distributeEvent(new FileUploadStatusChangedEvent(upload));
        }
    }

    reloadAll(uploads) {
        for (const upload of uploads) {
            let row = this.getRow(upload);

            if (row === null)
                this.createRow(upload)
            else
                this.updateRow(row, upload);
        }

        this.updateTitle();
    }

    updateTitle() {
        const query = new DomQuery(this.table);

        const total = this.table.rows.length - query.getDescendants(WithClass("Aborted")).length - query.getDescendants(WithClass("Forbidden")).length;
        const count = query.getDescendants(WithClass("Successful")).length;

        // TODO: Use proper translated caption.
        if (total === 0)
            this.title.innerText = "";
        else
            this.title.innerText = "Uploads: " + count + " / " + total;
    }

    connect(serviceWorker) {
        if (this.listener !== undefined)
            this.serviceWorker.removeEventListener("message", this.listener);

        this.listener = (event) => {
            const data = event.data;

            if (event.data.class === "Files") {
                if (Array.isArray(data.value))
                    this.reloadAll(data.value);
                else
                    this.reload(data.value);
            }

            this.updateState();
        }

        this.serviceWorker = serviceWorker;
        this.serviceWorker.addEventListener("message", this.listener);
    }
}
