class TemplateRenderer {
  renderTemplate(template, value) {
    const parser = new DOMParser();
    const templateDocument = parser.parseFromString(template, "application/xml");

    const result = document.createElement("main");

    for (const node of templateDocument.childNodes) {
      const component = this.renderElement(node, value);

      if (component !== null)
        result.appendChild(component);
    }

    return result;
  }

  renderDivision(node, value) {
    const result = document.createElement("div");

    for (const child of node.childNodes) {
      const component = this.renderElement(child, value);

      if (component !== null)
        result.appendChild(component);
    }

    for (const attribute of node.attributes) {
      if (attribute.name === "Classes")
        result.setAttribute("class", this.evaluate(attribute.value, value));
      else
        result.dataset[attribute.name] = this.evaluate(attribute.value, value);
    }

    return result;
  }

  renderElement(node, value) {
    if (node.tagName === "Division") {
      return this.renderDivision(node, value);
    }
    else if (node.tagName === "H1") {
      return this.renderHeader(node, value);
    }
    else if (node.nodeType === 3) {
      return this.renderText(node, value);
    }
    else
      return null;
  }

  renderHeader(node, value) {
    const result = document.createElement("h1");

    for (const child of node.childNodes) {
      const component = this.renderElement(child, value);

      if (component !== null)
        result.appendChild(component);
    }

    for (const attribute of node.attributes) {
      if (attribute.name === "Classes")
        result.setAttribute("class", this.evaluate(attribute.value, value));
      else
        result.dataset[attribute.name] = this.evaluate(attribute.value, value);
    }

    return result;
  }

  renderText(node, value) {
    return document.createTextNode(this.evaluate(node.textContent, value));
  }

  evaluate(text, value) {
    if (text.startsWith("Eval(") && text.endsWith(")")) {
      const expression = text.substring(5, text.length - 1);
      const result = value[expression];

      if (result !== undefined)
        return result;
      else
        return null;
    }
    else
      return text;
  }
}
