function RadioButtonField(element) {
    WebPageComponent.call(this, element);

    this.getName = function () {
        return this.name;
    }

    this.determineOptions = function () {
        var query = new DomQuery(this.element);
        var object = this;
        var options = query.getDescendants(function (node) { return node.tagName === "INPUT" && node.name === object.name});

        return options;
    }

    this.focus = function() {
        this.options[0].focus();
    }

    this.getValue = function () {
        var result = null;
        var index = 0;

        while (result == null && index < this.options.length) {
            if (this.options[index].checked)
                result = this.options[index].value;
            else
                index++;
        }

        return result;
    }

    this.addEventListener = function (event, handler) {
        for (var index = 0; index < this.options.length; index++)
            this.options[index].addEventListener(event, handler);
    }

    this.name = this.element.dataset.Name;
    this.options = this.determineOptions();
}

interactivityRegistration.register("RadioButtonField", function (element) { return new RadioButtonField(element); });
