function MetaKeys() {
    this.alt = false;
    this.shift = false;
    this.control = false;

    this.asURLParameters = function () {
        var result = "";

        if (this.alt)
            result += "&alt=1";

        if (this.control)
            result += "&control=1";

        if (this.shift)
            result += "&shift=1";

        return result;
    }

    this.asXMLAttributes = function () {
        var result = "";

        if (this.alt)
            result += " alt=\"1\"";

        if (this.control)
            result += " control=\"1\"";

        if (this.shift)
            result += " shift=\"1\"";

        return result;
    }
}

if (typeof(Event) != "undefined") {
    Event.prototype.getKey = function () {
        var result = this.keyCode;

        if (result == null || result == 0)
            result = this.which;

        return result;
    }

    Event.prototype.getMetaKeys = function() {
        var result = new MetaKeys();

        result.alt = this.altKey;
        result.control = this.ctrlKey;
        result.shift = this.shiftKey;

        return result;
    }

    Event.prototype.getPosition = function () {
        var result = new Point(0, 0);
        var source = this.getSource();

        result.x = this.offsetX;
        result.y = this.offsetY;

        if (result.x == null)
            result.x = this.layerX - source.offsetLeft - 1;

        if (result.y == null)
            result.y = this.layerY - source.offsetTop - 1;

        return result;
    }

    Event.prototype.getSource = function () {
        var result = this.target;

        if (!result)
            result = this.srcElement;

        return result;
    }

    Event.prototype.stopHandling = function () {
        this.cancelBubble = true;
        this.cancel = true;

        if (this.stopPropagation)
            this.stopPropagation();

        if (this.preventDefault)
            this.preventDefault();
        else
            this.returnValue = false;

        return false;    
    }

    Event.prototype.getButton = function () {
        if (isInternetExplorer6() || isInternetExplorer7() || isInternetExplorer8()) {
            if (this.button & 1)
                return 0;
            else if (this.button & 4)
                return 2;
            else if (this.button & 2)
                return 1;
        }
        else
            return this.button;
    }
}
else {
    function WindowEventAdapter(windowEvent) {
        this.windowEvent = windowEvent;

        this.getSource = function () {
            return this.windowEvent.srcElement;
        }

        this.getMetaKeys = function() {
            var result = new MetaKeys();

            result.alt = this.windowEvent.altKey;
            result.control = this.windowEvent.ctrlKey;
            result.shift = this.windowEvent.shiftKey;

            return result;
        }

        this.getPosition = function () {
            var result = new Point(0, 0);
            var source = this.getSource();

            result.x = this.windowEvent.offsetX;
            result.y = this.windowEvent.offsetY;

            if (result.x == null)
                result.x = this.windowEvent.layerX - source.offsetLeft - 1;

            if (result.y == null)
                result.y = this.windowEvent.layerY - source.offsetTop - 1;

            return result;
        }

        this.stopHandling = function () {
            this.cancelBubble = true;

            if (this.windowEvent.stopPropagation)
                this.windowEvent.stopPropagation();

            if (this.windowEvent.preventDefault)
                this.windowEvent.preventDefault();
            else
                this.windowEvent.returnValue = false;
        }
        
        this.getButton = function () {
            if (isInternetExplorer()) {
                if (this.windowEvent.button & 1)
                    return 0;
                else if (this.windowEvent.button & 4)
                    return 2;
                else if (this.windowEvent.button & 2)
                    return 1;
            }
            else
                return this.windowEvent.button;
        }
    }
}

function getEvent(event) {
    if (typeof(Event) != "undefined") {
        if (!event)
            return window.event;
        else
            return event;
    }
    else {
        return new WindowEventAdapter(window.event);
    }
}

function DataChangedEvent(field) {
    this.field = field;
}

function VisibilityChangedEvent(field) {
    this.field = field;
}

function AvailableSizeChangedEvent() {
}

function distributeEvent(event) {
    var query = new DomQuery(document.body);
    var object = event.field;

    query.traversePostOrder(
        function (element) {
            if (element.component !== undefined && element.component !== object && element.component.handleEvent !== undefined)
                element.component.handleEvent(event);
        }
    );
}

function distributeEventUpHierarchy(event, component) {
    event.handled = false;

    while (component !== null && !event.handled) {
        component.handleEvent(event);
        component = component.parentComponent;
    }
}

function clickElement(element) {
    var event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
    });

    element.dispatchEvent(event);
}
