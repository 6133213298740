class WebSiteObjectFilters extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.fields = new Array();
        this.defaultFields = new Array();
        this.uri = this.element.dataset.Uri;
        this.filterForm = null;

        // TODO: provide base uri server side.
        this.baseUri = this.uri.split(";", 1);

        this.determineElements();
        this.attachHandlers();
    }

    attachHandlers() {
        if (this.button != null)
            this.button.onclick = (event) => this.toggleAddForm();

        for (const value of this.values) {
            const close = new DomQuery(value).getChild(WithClass("Close"));
            close.onclick = this.createClearFilterHandler(value);
        }
    }

    bind() {
        if (this.filterForm !== null) {
            const form = this.filterForm.component;
            const section = form.childComponents[0];

            for (const child of section.childComponents)
                this.registerFilter(child.element, false)
        }

        for (const defaultFilter of this.defaultFilters)
            this.registerFilter(new DomQuery(defaultFilter).getChild(WithClass("Field")), true);
    }

    createClearFilterHandler(value) {
        const name = value.childNodes[1].dataset.Name;

        return (event) => {
            this.toolbar.removeChild(value);

            for (const field of this.fields) {
                if (field.getName().indexOf(name, field.getName().length - name.length) !== -1) {
                    field.setValue("");
                    this.filter();
                }
            }
        };
    }

    clearFilters() {
        this.reload(this.baseUri);
    }

    determineElements() {
        let query = new DomQuery(this.element);

        this.toolbar = query.getChild(WithClass("Toolbar"));
        this.header = new DomQuery(this.toolbar).getChild(WithClass("Header"));
        this.progress = new Progress(query.getChild(WithClass("Progress")));
        this.content = this.element.childNodes[3];

        query = new DomQuery(this.header);

        this.toggle = query.getChild(WithClass("ToggleFilters"));
        this.toggle.addEventListener(
            "click",
            (event) => {
                new HtmlClassSwitch(this.element, "Active").toggle();
            }
        )

        this.clear = query.getChild(WithClass("ClearFilters"));

        if (this.clear !== null) {
            this.clear.addEventListener(
                "click",
                () => {
                    this.clearFilters();
                }
            )
        }

        query = new DomQuery(this.toolbar);
        this.add = query.getChild(WithClass("Add"));
        this.defaultFilters = query.getDescendants(WithClass("DefaultFilter"));
        this.values = query.getDescendants(WithClass("FilterValue"));

        if (this.add !== null) {
            this.button = new DomQuery(this.add).getChild(WithTagName("BUTTON"));
            this.addExpanded = new HtmlClassSwitch(this.add, "Expanded");

            this.filterForm = new DomQuery(this.add).getChild(WithTagName("FORM"));
            this.filterForm.childNodes[1].childNodes[0].addEventListener(
                "click",
                () => {
                    this.toggleAddForm();
                    this.filter();
                }
            );
        }
    }

    filter() {
        this.reload(this.getUri());
    }

    reload(uri) {
        const client = new HttpClient();

        client.get(
            uri + ";$Filter",
            (response) => {
                const dummy = document.createElement("div");
                dummy.innerHTML = response.responseText;

                const newFilter = dummy.childNodes[0];
                newFilter.classList.add("Active");

                this.element.parentNode.replaceChild(newFilter, this.element);
                interactivityRegistration.attach(newFilter);
                window.history.pushState(
                    { url: uri },
                    null,
                    uri
                );
            },
            this.progress
        );
    }

    getQuery() {
        let query = "";

        for (const field of this.fields) {
            const name = field.getName();
            const value = field.getValue();

            if (value !== null && value.length > 0)
                query = query + encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";
        }

        if (query.length > 0)
            query = query.substring(0, query.length - 1);

        return query;
    }

    getUri() {
        let uri = this.baseUri;
        const query = this.getQuery();

        if (query.length > 0)
            uri = uri + ";" + query;

        return uri;
    }

    registerFilter(field, defaultFilter) {
        this.fields.push(field.component);

        if (defaultFilter) {
            this.defaultFields.push(field.component);
            field.component.addEventListener("change", (event) => { this.filter(); });
        }
    }

    toggleAddForm() {
        this.addExpanded.toggle();

        const listener = connectClickOutsideListener(
            this.add,
            (event) => {
                this.addExpanded.toggle();
                removeClickOutsideListener(listener);
            }
        );
    }
}

interactivityRegistration.register("Filtering", function(element) { return new WebSiteObjectFilters(element); });
