function ExpressionField(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        if (this.mode === ControlMode.edit) {
            this.element.onclick = function(event) { object.element.classList.add("Focus"); object.input.focus(); };
            this.input.onblur = function(event) { object.element.classList.remove("Focus")};
            this.input.onchange = function(event) { object.reload() }
        }
    }

    this.determineElements = function() {
        this.expression = this.element.childNodes[0];

        if (this.mode === ControlMode.edit) {
            this.input = new DomQuery(this.element).getChild(WithTagName("TEXTAREA"));
            this.input.spellcheck = false;
        }
    }

    this.reload = function() {
        var object = this;

        this.client.sendJsonRequest(
            this.element.dataset.Uri, {
                "Command": "Parse",
                "Value": this.input.value
            },
            function(response) {
                if (response.status === 200) {
                    var dummy = document.createElement("div");
                    dummy.innerHTML = response.responseText;

                    object.element.replaceChild(dummy.firstChild.firstChild, object.element.firstChild);

                    this.expression = object.element.firstChild;
                    this.expression.onclick = function(event) { object.element.classList.add("Focus"); object.input.focus(); };
                }
            }
        );
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("Expression", function (element) { return new ExpressionField(element); });
