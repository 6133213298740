function PasswordField(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        if (this.input !== null) {
            this.input.onfocus = function(event) { object.element.classList.add("Focus")};
            this.input.onblur = function(event) { object.element.classList.remove("Focus")};
        }
    }

    this.determineElements = function() {
        this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("PasswordField", function (element) { return new PasswordField(element); });
