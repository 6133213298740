function TextField(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function (event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        var object = this;

        if (this.input !== null) {
            this.input.onfocus = function(event) { object.element.classList.add("Focus")};
            this.input.onblur = function(event) { object.element.classList.remove("Focus")};

            if (this.checkBox !== null)
                this.checkBox.onchange = function(event) { object.valueChanged(); };
        }
    }

    this.determineElements = function() {
        this.checkBox = new DomQuery(this.element).getChild(WithClass("CheckBoxField"));

        if (this.checkBox !== null)
            this.checkBox = this.checkBox.childNodes[0];

        this.determineInputElement();
    }

    this.disable = function() {
        this.element.classList.add("Disabled");
    }

    this.enable = function() {
        this.element.classList.remove("Disabled");
    }

    this.focus = function() {
        this.input.focus();
    }

    this.getName = function() {
        if (this.input !== null)
            return this.input.name
        else
            return null;
    }

    this.getValue = function() {
        return this.input.value;
    }

    this.setValue = function(value) {
        this.input.value = value;
    }

    this.valueChanged = function() {
        this.input.readOnly = this.checkBox.checked;

        if (this.checkBox.checked)
            this.disable();
        else
            this.enable();
    }
}

function SingleLineTextField(element) {
    TextField.call(this, element);

    this.determineInputElement = function() {
        this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));
    }

    this.determineElements();
    this.attachHandlers();
}

function MultipleLineTextField(element) {
    TextField.call(this, element);

    this.determineInputElement = function() {
        this.input = new DomQuery(this.element).getChild(WithTagName("TEXTAREA"));
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("SingleLineTextField", function (element) { return new SingleLineTextField(element); });
interactivityRegistration.register("MultipleLineTextField", function (element) { return new MultipleLineTextField(element); });
