function UriField(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function (event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        var object = this;

        if (this.input !== null) {
            this.input.onfocus = function(event) { object.element.classList.add("Focus")};
            this.input.onblur = function(event) { object.element.classList.remove("Focus")};
        }       
    }
    
    this.determineElements = function() {
        this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));
    }

    this.focus = function() {
        this.input.focus();
    }

    this.getName = function() {
        return this.name;
    }

    this.getValue = function() {
        return this.input.value;
    }

    this.setValue = function(value) {
        this.input.value = value;
    }
    
    this.name = this.element.dataset.Name;

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("UriField", function (element) { return new UriField(element); });
