class ToastBox {
    constructor() {
        this.messages = new Array();

        this.initialize();
    }

    initialize() {
        this.element = document.createElement("div");
        this.element.classList.add("ToastBox");

        const target = document.getElementById("main");
        target.appendChild(this.element);
    }

    addMessage(message) {
        this.element.appendChild(message.render(this));
        this.messages.push(message);

        message.element.style.transition = 'opacity 1s ease';
        message.element.style.opacity = "0";

        setTimeout(() => { message.element.style.opacity = "1"; }, 50);

        if (message.type !== "Error")
            setTimeout(() => { this.removeMessage(message) }, 5000);
    }

    removeMessage(message) {
        const index = this.messages.indexOf(message);

        if (index !== -1) {
            this.messages.splice(index, 1);
            message.element.style.opacity = 0;

            setTimeout(() => { this.element.removeChild(message.element) }, 1000);
        }
    }
}

class ToastMessage {
    constructor(message, type) {
        this.message = message;
        this.type = type;

        this.id = crypto.randomUUID();
    }

    render(target) {
        const icon = document.createElement("span");
        icon.classList.add("Icon");

        const close = document.createElement("BUTTON");
        close.addEventListener("click", (event) => { target.removeMessage(this); })

        const message = document.createElement("SPAN");
        message.innerHTML = this.message;
        message.classList.add("Content");

        this.element = document.createElement("SPAN");
        this.element.dataset.Id = this.id;
        this.element.appendChild(icon);
        this.element.appendChild(close);
        this.element.appendChild(message);
        this.element.classList.add("Message");
        this.element.classList.add(this.type);

        return this.element;
    }
}
