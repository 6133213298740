function Bookmarks(element) {
  WebPageComponent.call(this, element);

  this.element = element;
  this.toggle = new HtmlClassSwitch(element, "Expanded");

  const query = new DomQuery(element);

  this.icon = query.getDescendant(WithClass("Icon"));
  this.contents = query.getDescendant(WithClass("Contents"));

  this.icon.addEventListener(
    'click',
    (event) => {
      this.toggleBookmarks();
    }
  );

  this.toggleBookmarks = function() {
    this.toggle.toggle();

    if (this.toggle.getStatus()) {
      this.clickOutsideListener = connectClickOutsideListener(
        this.contents,
        (event) => {
          this.toggleBookmarks();
          event.stopPropagation();
        }
      );
    }
    else
      removeClickOutsideListener(this.clickOutsideListener);
  }
}

interactivityRegistration.register("Bookmarks", function (element) { return new Bookmarks(element); });
