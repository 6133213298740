function MouseMove () {
    this.control = null;
    this.position = new Point(0, 0);
    this.metaKeys = null;
    this.timeStamp = 0;
}

function Control() {
    this.determineElements = function () {
        this.element = getCurrentScript().parentNode;
        this.element.control = this;
        
        this.menuElement = this.element.getElementsByClassName("menu Horizontal")[0];
        this.menuList    = this.menuElement.getElementsByTagName("li")[0];
        this.menu        = this.element.getElementsByTagName("ol")[1];
        this.image       = this.element.getElementsByTagName("img")[0];

        var divisions = this.element.getElementsByTagName("div");

        this.element.tabIndex = 0;
        this.element.oncontextmenu = function () { return false; };        

        this.focusedObject = divisions[0];
        this.hint = divisions[1];
    }

    this.sendEvents = function () {
        this.isDigesting = true;

        var object = this;
        var xmlRequest = newXmlRequest();

        var events = "<events isEditing=";

        if (this.isEditing) {
            events += "\"true\">";
        }
        else {
            events += "\"false\">";
        }

        for (var i = 0; i < this.events.length; i++)
            events += this.events[i];

        events = events + "</events>";
        this.events = new Array();

        xmlRequest.open("POST", this.url, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml");
        xmlRequest.onreadystatechange = function () { object.handleEventResponse(xmlRequest); };
        xmlRequest.send(events);
    }

    this.handleEventResponse = function (xmlRequest) {
        if (xmlRequest.readyState == 4) {
            var response = xmlRequest.responseXML;

            var focusedObject = response.getElementsByTagName("focusedObject")[0].getAttribute("value");
            this.focusedObject.innerHTML = focusedObject;
            setNodeClassEnabled(this.focusedObject, "visible", focusedObject != "");

            var hintElement = response.getElementsByTagName("hint")[0];
            var hint = hintElement.getAttribute("value");
            var hintHtmlElement = this.hint;

            hintHtmlElement.innerHTML = hint;
            setNodeClassEnabled(hintHtmlElement, "visible", hint != "");
            hintHtmlElement.style.left = hintElement.getAttribute("x") + "px";
            hintHtmlElement.style.top = hintElement.getAttribute("y") + "px";

            var imageElements = response.getElementsByTagName("image");

            if (imageElements.length > 0) {
                var image = this.image;
                var object = this;

                image.onload = function () {
                    object.handleDigestedEvents();

                    if (object.isEditing)
                        object.fireOnChange();
                };
                
                image.src = imageElements[0].getAttribute("uri");
            }
            else
                this.handleDigestedEvents();
        }
    }

    this.handleDigestedEvents = function () {
        if (this.events.length > 0)
            this.sendEvents();
        else
            this.isDigesting = false;
    }

    this.addEvent = function (event, parameters) {
        this.events.push("<" + event + " " + parameters + "/>");

        if (!this.isDigesting)
            this.sendEvents();
    }

    this.mouseDown = function (event) {
        var position = event.getPosition();
        var metaKeys = event.getMetaKeys();

        this.element.focus();
        this.image.style.cursor = "default";
        this.addEvent(
            "mouseDown",
            "x=\"" + position.x + "\" "  +
            "y=\"" + position.y + "\" " +
            "button=\"" + event.getButton() + "\" " +
            metaKeys.asXMLAttributes()
        );

        event.stopHandling();
    }

    this.mouseUp = function (event) {
        var position = event.getPosition();
        var metaKeys = event.getMetaKeys();

        this.addEvent(
            "mouseUp",
            "x=\"" + position.x + "\" " +
            "y=\"" + position.y + "\" " +
            "button=\"" + event.getButton() + "\" " +
            metaKeys.asXMLAttributes()
        );

        event.stopHandling();
    }

    this.mouseMove = function (event) {
        var position = event.getPosition();
        var metaKeys = event.getMetaKeys();

        // TODO: By tracking mouse position we know whether the mouse position has actually changed, we might want to
        //       move this functionality somewhere else.
        if (position.x != this.previousMousePosition.x || position.y != this.previousMousePosition.y) {
            this.addEvent(
                "mouseMove",
                "x=\"" + position.x + "\" " +
                "y=\"" + position.y + "\" " +
                metaKeys.asXMLAttributes()
            );
        }

        this.previousMousePosition = position;
        event.stopHandling();
    }

    this.mouseOut = function (event) {
        this.addEvent("mouseLeave", "");

        event.stopHandling();
    }

    this.mouseWheel = function (event) {
        var position = event.getPosition();
        var metaKeys = event.getMetaKeys();

        this.addEvent(
            "mouseWheel",
            "x=\"" + position.x + "\" " +
            "y=\"" + position.y + "\" " +
            "delta=\"" + event.wheelDelta + "\" " +
            metaKeys.asXMLAttributes()
        );

        event.stopHandling();
    }

    this.mouseWheelFireFox = function (event) {
        var position = event.getPosition();
        var metaKeys = event.getMetaKeys();

        this.addEvent(
            "mouseWheel",
            "x=\"" + position.x + "\" " +
            "y=\"" + position.y + "\" " +
            "delta=\"" + event.detail*(-120) + "\" " +
            metaKeys.asXMLAttributes()
        );

        event.stopHandling();
    }

    this.handleKey = function (event, keyEventName) {
        var keyCode = event.getKey();

        if (keyCode < 16 || keyCode > 18) {
            var metaKeys = event.getMetaKeys();

            this.addEvent(
                keyEventName,
                "keyCode=\"" + keyCode + "\" " +
                metaKeys.asXMLAttributes()
            );
        }

        event.stopHandling();
    }

    this.keyDown = function (event) {
        this.handleKey(event, "keyDown");
    }

    this.keyUp = function (event) {
        this.handleKey(event, "keyUp");
    }

    this.keyPress = function (event) {
        this.handleKey(event, "keyPress");
    }

    this.attachEventHandlers = function () {
        var image = this.image;
        var element = this.element;
        var object = this;

        if (!isInternetExplorer6()) {
            image.onmousedown = function (event) { object.mouseDown(getEvent(event)); };
            image.onmouseup = function (event) { object.mouseUp(getEvent(event)); };
            image.onmousemove = function (event) { object.mouseMove(getEvent(event)); };
            image.onmouseout = function (event) { object.mouseOut(getEvent(event)); };

            if (!isInternetExplorer7()) {
                image.onmousewheel = function (event) { object.mouseWheel(getEvent(event)); };
            }

            if (isChrome())
                image.addEventListener("mousewheel", function (event) { object.mouseWheel(getEvent(event)); }, true);            

            if (isFireFox())
                image.addEventListener("DOMMouseScroll", function (event) { object.mouseWheelFireFox(getEvent(event)); }, false);

            element.onkeydown = function (event) { object.keyDown(getEvent(event)); };
            element.onkeyup = function (event) { object.keyUp(getEvent(event)); };
            element.onkeypress = function (event) { object.keyPress(getEvent(event)); };
        }
    }

    this.toggleEditing = function (event) {
        this.isEditing = !this.isEditing;
        this.updateEditingIndicators();

        var object = this;
        var xmlRequest = newXmlRequest();
        var command = "<setEditing enabled=\"" + booleanToXML(this.isEditing) + "\"/>";

        xmlRequest.open("POST", this.url, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml");
        xmlRequest.send(command);
   }

    this.addEditMenuOption = function(caption, isEditing) {
        this.isEditing = isEditing; 

        var option = document.createElement("li");
        var span = document.createElement("span");
        var link = document.createElement("a");
        var object = this;

        link.href = "javascript:void(0);";
        link.innerHTML = caption;
        
        link.onclick = function (event) {
            object.toggleEditing(getEvent(event));
            object.menuElement.component.collapse(object.menuList, getEvent(event));
        };

        span.appendChild(link);
        option.appendChild(span);

        this.menu.insertBefore(option, this.menu.childNodes[0]);
        this.editMenuOption = option;
        this.updateEditingIndicators();
    }

    this.updateEditingIndicators = function () {
        setNodeClassEnabled(this.editMenuOption, "current", this.isEditing);
        setNodeClassEnabled(this.element, "editing", this.isEditing);
    }

    this.registerOnChangeListener = function (listener) {
        this.onChangeListeners.push(listener);
    }

    this.fireOnChange = function () {
        for (var i = 0; i < this.onChangeListeners.length; i++)
            this.onChangeListeners[i]();
    }

    this.events = new Array();
    this.isDigesting = false;
    this.previousMousePosition = new Point(0, 0);
    this.onChangeListeners = new Array();

    this.determineElements();
    this.url = this.image.src.split("?")[0];

    this.attachEventHandlers();
}
