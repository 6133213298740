function ValueTextParser() {
  this.parseText = function (value) {
    const escapedCharacters = "(),\\";

    let result = "";
    let escaped = false;

    for (const character of value) {
      if (escaped) {
        if (escapedCharacters.includes(character))
          result += character;
        else
          throw new Error("Unexpected escaped character: " + character);

        escaped = false;
      }
      else if (character === "\\")
        escaped = true;
      else
        result += character;
    }

    if (escaped)
      throw new Error("Unexpected escape character at end");

    return result;
  }
}
