function ImageViewer (imageIdentifiers, URI, embellished, maximumWidth) {
    this.viewer = getCurrentScript().parentNode;
    this.imageIdentifiers = imageIdentifiers;
    this.URI = URI;
    this.embellished = embellished;
    this.maximumWidth = maximumWidth;
    this.currentImageIdentifier;
    this.currentImage;
    this.viewPort;
    this.previousSelector;
    this.nextSelector;
    this.autoPlay;
    this.timer;
    this.overview;

    this.previous = function () {
        if (this.currentImageIdentifier > 0)
            this.currentImageIdentifier = this.currentImageIdentifier - 1;
        else
            this.currentImageIdentifier = this.imageIdentifiers.length - 1;

        this.render();
    }

    this.next = function () {
        if (this.currentImageIdentifier < this.imageIdentifiers.length - 1)
            this.currentImageIdentifier = this.currentImageIdentifier + 1;
        else
            this.currentImageIdentifier = 0;

        this.render();
    }

    this.select = function (imageIndex) {
        this.currentImageIdentifier = imageIndex;

        this.render();
    }

    this.handlePreviousClick = function() {
        var viewer = this;

        return function () {
            viewer.previous();
        }
    }

    this.handleNextClick = function() {
        var viewer = this;

        return function () {
            viewer.next();
        }
    }

    this.handleSelectClick = function(imageIndex) {
        var viewer = this;

        return function () {
            viewer.select(imageIndex);
        }
    }

    this.render = function () {
        this.currentImage.src = this.URI + this.imageIdentifiers[this.currentImageIdentifier] + "?Scaled=true&backgroundColor=FFFFFF&maximumWidth=" + this.maximumWidth;

        if (this.embellished == "True") {
            this.currentImage.src = this.currentImage.src + "&shadow=" + this.embellished;
        }

        if (this.autoPlay) {
            this.timer.kill();
            this.timer.schedule(this.handleNextClick());
        }
    }

    this.renderOverview = function () {
        var image;
        
        for (var i = 0; i < this.imageIdentifiers.length; i++) {
            image = new Image();
            image.src = this.URI + this.imageIdentifiers[i] + "?Scaled=true&backgroundColor=FFFFFF&maximumHeight=50&shadow=" + this.embellished;
            image.className = "miniature"; 
            image.onclick = this.handleSelectClick(i);

            this.overview.appendChild(image);
        }
    }

    this.startAutoPlay = function () {
        this.autoPlay = true;
        this.timer = new Timer(7000);
        this.timer.schedule(this.handleNextClick());
    }

    this.create = function () {
        this.currentImageIdentifier = 0;
        this.currentImage = new Image();
        this.autoPlay = false;
        this.overview = document.createElement('div');
        this.overview.className = "overview";

        var table = document.createElement('table');
        var tableBody = document.createElement('tbody');
        var row = document.createElement('tr');

        tableBody.appendChild(row);
        table.appendChild(tableBody);

        this.viewPort = document.createElement('td');
        this.viewPort.className = "viewPort";
        this.viewPort.appendChild(this.currentImage);

        this.previousSelector = document.createElement('td');
        this.previousSelector.className = "previous";
        this.previousSelector.innerHTML = "&nbsp;";
        this.previousSelector.onclick = this.handlePreviousClick();

        this.nextSelector = document.createElement('td');
        this.nextSelector.className = "next";
        this.nextSelector.innerHTML = "&nbsp;";
        this.nextSelector.onclick = this.handleNextClick();        

        row.appendChild(this.previousSelector);
        row.appendChild(this.viewPort);
        row.appendChild(this.nextSelector);

        this.viewer.appendChild(table);
        this.viewer.appendChild(this.overview);
    }

    this.create();
}

