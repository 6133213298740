class GridPanel extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.uri = this.element.dataset.Uri;
        this.contents = new DomQuery(this.element).getChild(WithClass("Contents"));
        this.resizable = this.element.dataset.Resizable === "true";
        this.edit = new HtmlClassSwitch(this.element, "Edit");

        this.gridItems = new Array();
        this.initialize();
    }

    initialize() {
        if (this.resizable) {
            this.settings = new DomQuery(this.element).getChild(WithClass("Settings"));

            this.resetButton = new DomQuery(this.settings).getChild(WithClass("Reset"));
            this.resetButton.addEventListener("click", (event) => { this.reset(); });

            this.configurationButton = new DomQuery(this.settings).getChild(WithClass("Configuration"));
            this.configurationButton.addEventListener("click", (event) => { this.edit.toggle(); });

            this.overlay = document.createElement("div");
            this.overlay.classList.add("GridOverlay");
    
            this.contents.appendChild(this.overlay);    
        }
    }

    bind() {
        const gridItems = new DomQuery(this.contents).getChildren(WithClass("GridItem"));

        for (const gridItem of gridItems)
            this.gridItems.push(gridItem.component);

        this.recalculate();
    }

    disableOverlay(action) {
        this.element.classList.remove(action);
        this.overlay.classList.remove("Visible");
    }

    enableOverlay(action) {
        this.element.classList.add(action);
        this.overlay.classList.add("Visible");
    }

    handleEvent(event) {
        if (event instanceof DataChangedEvent)
            this.reload();
    }

    persist() {
        const items = new Array();

        for (const gridItem of this.gridItems) {
            gridItem.preferredBounds = gridItem.bounds.copy();

            items.push({
                Identifier: gridItem.element.dataset.Identifier,
                Enabled: gridItem.enabled,
                Bounds: {
                    Left: gridItem.bounds.left - 1,
                    Top: gridItem.bounds.top - 1,
                    Right: gridItem.bounds.right - 2,
                    Bottom: gridItem.bounds.bottom - 2
                }
            });
        }

        fetch(
            this.uri,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(items)
            }
        );
    }

    reload() {
        fetch(
            this.uri,
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => this.reloadFromResponse(data));
    }

    reloadFromResponse(data) {
        for (const item of data) {
            const gridItem = this.gridItems.find((element) => { return element.element.dataset.Identifier === item.Identifier });
            gridItem.enabled = item.Enabled;

            if (gridItem !== null)
                gridItem.setBounds(new Rectangle(item.Bounds.Left + 1, item.Bounds.Top + 1, item.Bounds.Right + 2, item.Bounds.Bottom + 2));
        }

        this.recalculate();
    }

    reset() {
        fetch(
            this.uri,
            {
                method: "DELETE",
            }
        )
            .then(() => this.reload());
    }

    recalculate() {
        this.bounds = new Rectangle(1, 1, 2, 2);

        for (const gridItem of this.gridItems) {
            if (gridItem.enabled) {
                this.bounds.left = Math.min(gridItem.bounds.left, this.bounds.left);
                this.bounds.right = Math.max(gridItem.bounds.right, this.bounds.right);
                this.bounds.top = Math.min(gridItem.bounds.top, this.bounds.top);
                this.bounds.bottom = Math.max(gridItem.bounds.bottom, this.bounds.bottom);
            }
        }

        this.updateGrid();

        if (this.resizable)
            this.updateOverlay();
    }

    updateGrid() {
        if (this.resizable) {
            this.contents.style.gridTemplateRows = `repeat(${this.bounds.height()}, 100px)`;
            this.contents.style.gridTemplateColumns = `repeat(${this.bounds.width()}, 100px)`;
        }
        else {
            this.contents.style.gridTemplateRows = `repeat(${this.bounds.height()}, auto)`;
            this.contents.style.gridTemplateColumns = `repeat(${this.bounds.width()}, minmax(0, 1fr))`;
        }
    }

    toggleAddForm(event, x, y) {
        const form = document.createElement("div");
        form.classList.add("GridForm");

        const title = document.createElement("span");
        title.innerHTML = this.settings.dataset.AddWidgetLabel;
        title.classList.add("Title");

        form.appendChild(title);

        for (const item of this.gridItems.filter((element) => { return !element.enabled; })) {
            const element = document.createElement("span");
            element.innerHTML = item.element.dataset.Title;
            element.classList.add("Item");
            element.addEventListener("click", (event) => {
                item.enabled = true;
                item.setBounds(
                    new Rectangle(
                        x,
                        y,
                        x + item.bounds.width(),
                        y + item.bounds.height()
                    )
                );

                this.resolveConflicts(item);
                this.persist();

                this.popUp.close();
                this.popUp = null;
            });

            form.appendChild(element);
        }

        this.popUp = new PopUp(form, event.target, false);
        this.popUp.open();
    }

    updateOverlay() {
        this.overlay.innerHTML = "";
        this.overlay.style.gridTemplateRows = `repeat(${this.bounds.height() + 1}, 100px)`;
        this.overlay.style.gridTemplateColumns = `repeat(${this.bounds.width() + 1}, 100px)`;

        const hasDisabledItems = this.gridItems.some((element) => { return !element.enabled });

        for (let x = this.bounds.left; x <= this.bounds.right; x++) {
            for (let y = this.bounds.top; y <= this.bounds.bottom; y++) {
                const item = document.createElement("div");
                item.style.gridColumnStart = x;
                item.style.gridColumnEnd = x + 1;
                item.style.gridRowStart = y;
                item.style.gridRowEnd = y + 1;

                if (this.isValid(new Rectangle(x, y, x + 1, y + 1))) {
                    item.classList.add("Empty");

                    if (hasDisabledItems) {
                        const button = document.createElement("button");
                        button.addEventListener("click", (event) => { this.toggleAddForm(event, x, y) });
                        button.title = this.settings.dataset.AddWidgetLabel;

                        item.appendChild(button);
                    }
                }

                this.overlay.appendChild(item);
            }
        }
    }

    isValid(bounds) {
        let result = true;
        let index = 0;

        while (index < this.gridItems.length && result) {
            const gridItem = this.gridItems[index];
            result = !gridItem.enabled || !gridItem.bounds.overlaps(bounds);
            index++;
        }

        return result;
    }

    resolveConflicts(modified) {
        let sorted = this.gridItems.toSorted((a, b) => { return a.bounds.left - b.bounds.left });
        sorted.splice(sorted.indexOf(modified), 1);
        sorted.unshift(modified);

        for (const item of sorted) {
            item.bounds = item.preferredBounds.copy();
            item.boundsChanged();
        }

        for (let index = 1; index < sorted.length; index++) {
            const item = sorted[index];

            if (item.enabled) {
                for (let otherIndex = 0; otherIndex < index; otherIndex++) {
                    const other = sorted[otherIndex];

                    if (other.enabled) {
                        while (item.bounds.overlaps(other.bounds)) {
                            const width = item.bounds.width();

                            item.bounds.left = item.bounds.left + 1;
                            item.bounds.right = item.bounds.left + width;
                            item.boundsChanged();
                        }
                    }
                }
            }
        }

        this.recalculate();
    }
}

class GridItem extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.setBounds(
            new Rectangle(
                parseInt(element.dataset.Left) + 1,
                parseInt(element.dataset.Top) + 1,
                parseInt(element.dataset.Right) + 2,
                parseInt(element.dataset.Bottom) + 2
            )
        );
        this.enabledClassSwitch = new HtmlClassSwitch(this.element, "Enabled");
        this.enabledClassSwitch.setStatus(this.element.dataset.Enabled === "true");

        if (this.element.childNodes.length === 2) {
            this.content = this.element.childNodes[1];

            if (this.content.component !== undefined) {
                this.content.component.handleEvent(new VisibilityChangedEvent(this));
            }
        }

        this.header = new DomQuery(element).getChild(WithClass("Header"));
    }

    reload() {
        fetch(
            this.uri,
            {
                method: "GET"
            }
        )
            .then((response) => this.reloadFromResponse(response));
    }

    async reloadFromResponse(response) {
        const dummyElement = document.createElement("div");
        dummyElement.innerHTML = await response.text();

        interactivityRegistration.detach(this.content);
        this.element.replaceChild(dummyElement.firstChild, this.content);
        this.content = this.element.childNodes[1];
        interactivityRegistration.attach(this.content);
    }

    setBounds(bounds) {
        this.bounds = bounds;
        this.preferredBounds = this.bounds.copy();
        this.boundsChanged();
    }

    boundsChanged() {
        this.element.style.gridColumn = this.bounds.left;
        this.element.style.gridColumnEnd = this.bounds.right;
        this.element.style.gridRow = this.bounds.top;
        this.element.style.gridRowEnd = this.bounds.bottom;
    }

    attachMoveHandlers() {
        let startX, startY, startBounds;

        this.moveStartHandler = (event) => {
            if (event.button === 0 && this.parentComponent.edit.getStatus()) {
                startBounds = this.bounds.copy();
                startX = event.clientX;
                startY = event.clientY;

                this.startEdit("Moving");

                document.addEventListener("mousemove", this.moveHandler);
                document.addEventListener("mouseup", this.moveEndHandler);

                event.preventDefault();
            }
        };

        this.moveHandler = (event) => {
            const bounds = this.element.getBoundingClientRect();
            const width = bounds.width;
            const height = bounds.height;

            const columnWidth = width / startBounds.width();
            const rowHeight = height / startBounds.height();

            const offsetX = Math.max(Math.round((event.clientX - startX) / columnWidth), -startBounds.left + 1)
            const offsetY = Math.max(Math.round((event.clientY - startY) / rowHeight), -startBounds.top + 1);

            this.setBounds(
                new Rectangle(
                    startBounds.left + offsetX,
                    startBounds.top + offsetY,
                    startBounds.right + offsetX,
                    startBounds.bottom + offsetY
                )
            );
            this.parentComponent.resolveConflicts(this);

            event.preventDefault();
        };

        this.moveEndHandler = () => {
            this.stopEdit("Moving");

            document.removeEventListener("mousemove", this.moveHandler);
            document.removeEventListener("mouseup", this.moveEndHandler);

            this.parentComponent.persist();
        };

        this.header.addEventListener("mousedown", this.moveStartHandler);
    }

    attachResizeHandlers() {
        this.resizeStartHandler = (event) => {
            if (event.button === 0) {
                this.startEdit("Resizing");

                document.addEventListener("mousemove", this.resizeHandler);
                document.addEventListener("mouseup", this.resizeEndHandler);

                event.preventDefault();
            }
        }

        this.resizeHandler = (event) => {
            const bounds = this.element.getBoundingClientRect();
            const width = bounds.width;
            const height = bounds.height;

            const newWidth = event.clientX - bounds.left;
            const newHeight = event.clientY - bounds.top;

            const columnWidth = width / this.bounds.width();
            const rowHeight = height / this.bounds.height();

            this.setBounds(
                new Rectangle(
                    this.bounds.left,
                    this.bounds.top,
                    Math.max(
                        this.bounds.left + Math.round(newWidth / columnWidth),
                        this.bounds.left + 1
                    ),
                    Math.max(
                        this.bounds.top + Math.round(newHeight / rowHeight),
                        this.bounds.top + 1
                    )
                )
            );
            this.parentComponent.resolveConflicts(this);

            event.preventDefault();
        }

        this.resizeEndHandler = (event) => {
            this.stopEdit("Resizing");

            document.removeEventListener("mousemove", this.resizeHandler);
            document.removeEventListener("mouseup", this.resizeEndHandler);

            this.parentComponent.persist();
        }

        this.resizer.addEventListener("mousedown", this.resizeStartHandler);
    }

    disable(event) {
        this.enabled = false;

        this.parentComponent.recalculate();
        this.parentComponent.persist();
    }

    bind() {
        this.resizable = this.parentComponent.resizable;

        if (this.resizable) {
            this.delete = new DomQuery(this.header).getChild(WithClass("Delete"));
            this.delete.addEventListener("mousedown", (event) => { event.stopPropagation() });
            this.delete.addEventListener("click", (event) => { this.disable(event); });

            this.resizer = document.createElement("span");
            this.resizer.classList.add("Resizer");

            this.element.appendChild(this.resizer);

            this.attachResizeHandlers();
            this.attachMoveHandlers();
        }
    }

    startEdit(action) {
        this.element.classList.add("Target");
        this.parentComponent.enableOverlay(action);
    }

    stopEdit(action) {
        this.element.classList.remove("Target");
        this.parentComponent.disableOverlay(action);
    }

    get enabled() {
        return this.element.dataset.Enabled === "true";
    }

    set enabled(value) {
        if (this.enabled !== value) {
            this.reload();

            this.element.dataset.Enabled = JSON.stringify(value);
            this.enabledClassSwitch.setStatus(this.enabled);
        }
    }

    get uri() {
        return this.element.dataset.Uri;
    }
}

class Rectangle {
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;

        this.height = function () {
            return this.bottom - this.top;
        };

        this.width = function () {
            return this.right - this.left;
        };

        this.copy = function () {
            return new Rectangle(this.left, this.top, this.right, this.bottom);
        };

        this.overlaps = function (other) {
            return (
                this.left < other.right &&
                this.right > other.left &&
                this.top < other.bottom &&
                this.bottom > other.top
            );

        };
    }
}

interactivityRegistration.register("Grid", function (element) { return new GridPanel(element); });
interactivityRegistration.register("GridItem", function (element) { return new GridItem(element); });
