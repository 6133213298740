class Task extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.parent = null;
        this.count = null;
        this.children = null;
        this.expanded = null;

        this.determineElements();
        this.attachEventHandlers();
    }

    determineElements() {
        const query = new DomQuery(this.element);

        this.parent = query.getDescendant(WithClass("Parent"));
        this.count = query.getDescendant(WithClass("Count"));
        this.children = query.getDescendant(WithClass("Children"));

        if (this.children !== null) {
            this.expanded = new HtmlClassSwitch(this.element, "Expanded");
            this.expanded.setStatus(false);
        }
    }

    attachEventHandlers() {
        if (this.count !== null && this.children !== null) {
            this.count.onclick = (event) => {
                this.iconClicked(getEvent(event));
            };
        }
    }

    iconClicked(event) {
        this.expanded.toggle();
        getEvent(event).stopHandling();
    }
}

class NotificationCenterComponent extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.updatingSwitch = new HtmlClassSwitch(element, "Updating");
        this.uri = element.dataset.Uri;
        this.renderTime = element.dataset.RenderTime;
        this.toggleSwitch = new HtmlClassSwitch(element, "Collapsed");

        this.determineElements();
        this.attachToolbarEventHandlers();
        this.initialize();

        mediumScreenQuery.addEventListener(
            'change',
            (event) => {
                if (event.matches && !this.toggleSwitch.getStatus())
                    this.toggle();
            }
        );

        if (mediumScreenQuery.matches && !this.toggleSwitch.getStatus())
            this.toggle();
    }

    determineElements() {
        const query = new DomQuery(this.element);

        this.contents = query.getChild(WithClass("Contents"));
        this.widgets = new DomQuery(this.contents).getChildren(WithClass("widget"));

        this.toggleButton = query.getDescendant(WithClass("Toggle"));
        this.refreshButton = query.getDescendant(WithClass("Refresh"));

        if (this.element.dataset.Count > 0)
            this.toggleButton.childNodes[0].innerHTML = this.element.dataset.Count;
    }

    handleEvent(event) {
        if (event instanceof DataChangedEvent || event instanceof NavigationEvent)
            this.refresh();
    }

    initialize() {
        if (this.renderTime === "Deferred")
            this.refresh();
    }

    toggle() {
        this.updatingSwitch.setStatus(true);
        this.toggleSwitch.toggle();

        const state = { Collapsed: this.toggleSwitch.getStatus() };
        const field = this;

        setTimeout(
            () => distributeEvent(new AvailableSizeChangedEvent()),
            500
        );

        fetch(this.uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(state)
        })
            .then((response) => field.updatingSwitch.setStatus(false))
            .catch((error) => field.updatingSwitch.setStatus(false));
    }

    reload(html) {
        const dummyElement = document.createElement("div");
        dummyElement.innerHTML = html;
        const newElement = dummyElement.firstChild;

        interactivityRegistration.detach(this.element);
        this.element.parentNode.replaceChild(newElement, this.element);
        interactivityRegistration.attach(newElement);
    }

    refresh() {
        if (!this.updatingSwitch.getStatus()) {
            this.updatingSwitch.setStatus(true);

            fetch(
                this.uri, {
                method: "GET",
                headers: {
                    "Accept": "text/html"
                }
            })
                .then(response => response.text())
                .then(html => this.reload(html))
                .catch(error => console.error(error))
                .finally(() => this.updatingSwitch.setStatus(false));
        }
    }

    attachToolbarEventHandlers() {
        this.toggleButton.addEventListener('click', (event) => this.toggle());
        this.refreshButton.addEventListener('click', (event) => this.refresh());
    }
}

interactivityRegistration.register("Task", function(element) { return new Task(element); });
interactivityRegistration.register("NotificationCenter", function(element) { return new NotificationCenterComponent(element); });
