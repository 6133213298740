function WebSiteToolbar(element) {
  WebPageComponent.call(this, element);

  this.attachHandlers = function() {
    var object = this;

    this.toggle.addEventListener("click", function(event) { object.expanded.toggle(); });
  }

  this.determineElements = function() {
    this.toggle = new DomQuery(this.element).getChild(WithClass("Toggle"));
  }

  this.expanded = new HtmlClassSwitch(this.element, "Expanded");

  this.determineElements();
  this.attachHandlers();
} 

interactivityRegistration.register(
  "Toolbar", 
  function (element) { 
    // TODO: We should find a better way to distinguish between different toolbars.
    if (element.id === "toolbar") 
      return new WebSiteToolbar(element, true); 
    else 
      return null;
  }
);
