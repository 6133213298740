function Collapsible(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;
        this.toggleElement.onclick = function (event) { object.toggle(event); };
    }

    this.determineElements = function() {
        this.collapsed = new HtmlClassSwitch(this.element, "Collapsed");

        var query = new DomQuery(this.element);
        var toggle = query.getDescendant(WithClass("Toggle"));

        this.toggleElement = toggle !== null ? toggle : this.element;
        this.toggleElement.tabIndex = 0;
    }

    this.toggle = function(event) {
        if (event.target.href === undefined) {
            this.collapsed.toggle();
            this.toggleElement.setAttribute('aria-expanded', !this.collapsed.getStatus());
        }
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("Collapsible", function (element) { return new Collapsible(element); });
