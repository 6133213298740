function FormBuilder(element) {
    WebPageComponent.call(this, element);

    this.add = function(element) {
        if (this.target.target.container)
            this.target.target.appendChild(element);
        else
            this.target.target.after(element);

        this.setTarget(element);
    }

    this.closeInspector = function(component) {
        if (this.inspector.childNodes.length > 1 && this.target.header != undefined)
            this.target.header.parentNode.insertBefore(this.inspector.childNodes[1], this.target.header.nextSibling);
    }

    this.createAddComponentHandler = function(component) {
        var object = this;

        return function (event) {
            object.add(component.createInstance(object, object.xmlDocument.createElement(component.name)));
            object.editorComponent.writeToElement();
            object.componentList.toggle();
        };
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        // Main components
        this.toolbar = query.getChild(WithClass("Toolbar"));
        this.progress = new Progress(query.getChild(WithClass("Progress")));
        this.problems = query.getChild(WithClass("Problems"));
        this.editor = query.getChild(WithClass("Editor"));
        this.inspector = query.getChild(WithClass("Inspector"));
        this.previewElement = query.getChild(WithClass("Preview"));
        this.tools = query.getChild(WithClass("Components"));
        this.valueField = query.getChild(WithTagName("INPUT"));

        this.componentElements = query.getDescendants(WithClass("Component"));

        this.pristine = this.valueField.value;
    }

    this.getComponent = function(name, type) {
        for (var index = 0; index < this.components.length; index++) {
            var component = this.components[index];

            if (component.name == name) {
                if (type == undefined)
                    return component;
                else if (component.element.dataset.FieldType == type)
                    return component;
            }
        }
    }

    this.initialize = function() {
        this.initializeToolbar();
        this.initializeComponents();

        this.xmlDocument = document.implementation.createDocument("", "", null);

        this.initializeEditor();
        this.loadForm();

        var object = this;

        this.observer = new MutationObserver(function (mutations) {object.updateValue();});
        this.observer.observe(this.editorComponent.object, { attributes: true, childList: true, subtree: true });

        this.editor.scrollTop = 0;
        this.updateToolbar();
    }

    this.initializeComponents = function() {
        this.preview = new HtmlClassSwitch(this.element, "Preview");
        this.componentList = new HtmlClassSwitch(this.tools, "Expanded");

        for (var index = 0; index < this.componentElements.length; index++) {
            var component = createFormBuilderComponent(this.componentElements[index], this.isEditable());

            component.element.onclick = this.createAddComponentHandler(component);
            this.components.push(component);
        }
    }

    this.initializeEditor = function() {
        var object = this;

        this.editorComponent = new Editor(this.editor, this.xmlDocument.createElement("Form"), "Form");
        this.editor.onclick = function (event) {
            object.setTarget(object.editorComponent);

            if (event != null)
                event.stopPropagation();
        };
    }

    this.initializeToolbar = function() {
        var object = this;
        var query = new DomQuery(this.toolbar);

        // Toolbar actions
        this.saveButton = query.getChild(WithClass("Save"));
        this.previousButton = query.getChild(WithClass("Previous"));
        this.nextButton = query.getChild(WithClass("Next"));
        this.addButton = query.getChild(WithClass("Add"));
        this.previewButton = query.getChild(WithClass("Preview"));
        this.submitButton = query.getChild(WithClass("Submit"));

        this.previewButton.onclick = function(event) { object.togglePreview() }
    }

    this.isEditable = function() {
        return this.mode === ControlMode.edit;
    }

    this.loadChildren = function(element, parent) {
        var query = new DomQuery(parent.element);

        for (var index = 0; index < element.childNodes.length; index++) {
            var child = element.childNodes[index];

            var component = this.getComponent(child.tagName, child.getAttribute("FieldType"));

            if (component != undefined) {
                instance = component.createInstance(this, child);
                this.add(instance);

                if (instance.container)
                    this.loadChildren(child, instance);

                this.setTarget(parent);
            }
            else {
                var value = query.getChild(WithClass(child.tagName));

                if (value != undefined && value.component != undefined && value.component.container)
                    this.loadChildren(child, value.component);
            }
        }
    }

    this.loadForm = function() {
        var value = this.valueField.value;
        this.setTarget(this.editorComponent);

        if (value.length > 0) {
            var parser = new DOMParser();
            var element = parser.parseFromString(value, "application/xml");

            if (element != undefined) {
                this.editor.component.object = element.childNodes[0];
                this.loadChildren(element.childNodes[0], this.target);
            }
        }
    }

    this.next = function() {
        this.history.push(this.valueField.value);
        this.valueField.value = this.future.shift();
        this.reload();
        this.valueChanged();
    }

    this.openInspector = function() {
        if (this.target.form != undefined)
            this.inspector.appendChild(this.target.form);
    }

    this.openPreview = function() {
        var object = this;

        this.previewElement.innerHTML = "";
        this.toggleButton(false, this.saveButton, null);
        this.toggleButton(false, this.previousButton, null);
        this.toggleButton(false, this.nextButton, null);
        this.toggleButton(false, this.addButton, null);
        this.toggleButton(true, this.submitButton, function(event) { object.submit(); });

        this.client.sendJsonRequest(
            this.element.dataset.Uri,
            {
                "Command": "Preview"
            },
            function(response) {
                if (response.status === 200) {
                    interactivityRegistration.detach(object.previewElement);
                    object.previewElement.innerHTML = response.responseText;
                    interactivityRegistration.attach(object.previewElement);
                }
            },
            object.progress
        );
    }

    this.previous = function() {
        this.future.unshift(this.valueField.value);
        this.valueField.value = this.history.pop();
        this.reload();
        this.valueChanged();
    }

    this.reload = function() {
        this.observer.disconnect();
        this.editorComponent.object = this.xmlDocument.createElement("Form");
        this.editorComponent.clear();
        this.loadForm();
        this.editor.scrollTop = 0;
        this.observer.observe(this.editorComponent.object, { attributes: true, childList: true, subtree: true });
    }

    this.remove = function(element) {
        element.remove();
        this.setTarget(element.parent);
    }

    this.save = function() {
        var object = this;

        if (this.pristine !== this.valueField.value) {
            this.client.sendJsonRequest(
                this.element.dataset.Uri,
                {
                    "Command": "Save",
                    "Value": this.valueField.value
                },
                function(response) {
                    if (response.status === 200) {
                        object.setSuccessful();
                        object.pristine = object.valueField.value;
                        object.valueChanged();
                    }
                    else if (response.status === 400) {
                        object.toolbar.classList.add("Error");
                        var listener = connectClickOutsideListener(object.toolbar, function(event){
                            object.toolbar.classList.remove("Error");
                            object.problems.innerHTML = "";
                            removeClickOutsideListener(listener);
                        });

                        object.problems.innerHTML = response.responseText;
                    }
                },
                object.progress
            );
        }
    }

    this.setSuccessful = function() {
        var object = this;

        this.toolbar.classList.add("Successful");
        var listener = connectClickOutsideListener(object.toolbar, function(event){ object.toolbar.classList.remove("Successful"); removeClickOutsideListener(listener); });
    }

    this.setTarget = function(element) {
        if (element.canFocus()) {
            if (this.target != undefined) {
                this.target.blur();

                this.closeInspector();
            }

            this.target = element;
            this.target.focus();

            this.openInspector();
        }
        else if (element.parent.canFocus())
            this.setTarget(element.parent);
    }

    this.submit = function(element) {
        var object = this;
		var formData = new FormData(this.previewElement.childNodes[0]);

		formData.append("Action", "Submit");

		this.client.postFormRequest(
            this.element.dataset.Uri,
            formData,
            function (xmlRequest) {
                if (xmlRequest.status === 200) {
                    object.setSuccessful();
                    object.previewElement.innerHTML = xmlRequest.responseText;
                    interactivityRegistration.attach(object.previewElement);
                }
            },
            this.progress
        );
    }

    this.toggleButton = function(status, button, function_) {
        if (status) {
            button.onclick = function_
            button.classList.add("Enabled");
        }
        else {
            button.onclick = null;
            button.classList.remove("Enabled");
        }
    }

    this.togglePreview = function() {
        if (!this.preview.getStatus())
            this.openPreview();
        else
            this.updateToolbar();

        this.preview.toggle();
    }

    this.updateToolbar = function() {
        var object = this;

        this.toggleButton(this.isEditable() && this.valueField.value !== this.pristine, this.saveButton, function(event) { object.save();});
        this.toggleButton(this.isEditable() && this.history.length > 0, this.previousButton, function(event) {object.previous();});
        this.toggleButton(this.isEditable() && this.future.length > 0, this.nextButton, function(event) {object.next();});
        this.toggleButton(this.isEditable(), this.addButton, function(event) { object.componentList.toggle(); });
        this.toggleButton(false, this.submitButton, null);
    }

    this.updateValue = function() {
        var serializer = new XMLSerializer();
        var previous = this.valueField.value;

        this.valueField.value = serializer.serializeToString(this.editorComponent.object);

        if (this.valueField.value !== previous) {
            this.history.push(previous);
            this.future = new Array();
            this.valueChanged();
        }
    }

    this.valueChanged = function() {
        this.updateToolbar();
    }

    this.components = new Array();
    this.history = new Array();
    this.future = new Array();

    this.determineElements();
    this.initialize();
}

function createFormBuilderComponent(element, editable) {
    var name = element.dataset.Name;

    if (name === "Section")
        return new FormSectionComponent(element, editable);
    else if (name === "Division")
        return new FormEmbedComponent(element, editable);
    else if (name === "Field")
        return new FormFieldComponent(element, editable);
    else
        return new FormBuilderComponent(element, editable);
}

function FormBuilderComponent(element, editable) {
    WebPageComponent.call(this, element);

    this.createElement = function() {
        var instance = this.element.cloneNode();

        var close = document.createElement("div");
        close.classList.add("Close");
        close.tabIndex = 0;

        var up = document.createElement("div");
        up.classList.add("Up");
        up.tabIndex = 0;

        var down = document.createElement("div");
        down.classList.add("Down");
        down.tabIndex = 0;

        var icon = document.createElement("div");
        icon.classList.add("Icon");

        var title = document.createElement("div");
        title.classList.add("Title");

        var header = document.createElement("div");
        header.classList.add("Header");
        header.appendChild(icon);
        header.appendChild(title);

        if (this.captionElement !== null)
            title.innerHTML = this.captionElement.innerHTML;

        var form = document.createElement("div");
        form.classList.add("Properties");

        if (this.form !== null) {
            form.innerHTML = this.form.outerHTML;
            interactivityRegistration.attach(form);
        }

        if (this.canFocus)
            instance.tabIndex = 0;

        if (this.closable && this.editable) {
            instance.appendChild(close);
            instance.appendChild(up);
            instance.appendChild(down);
        }

        instance.appendChild(header);
        instance.appendChild(form);

        return instance;
    }

    this.createFormBuilderElement = function(editor, value) {
        var element = this.createElement();
        var instance = new DefaultFormBuilderElement(element, value, this);

        return instance;
    }

    this.createInstance = function(editor, value) {
        var instance = this.createFormBuilderElement(editor, value);

        if (instance.canFocus()) {
            instance.element.onclick = function (event) {
                editor.setTarget(instance);

                if (event != null)
                    event.stopPropagation();
            };
        }
        else
            instance.element.onclick = function (event) {
                if (event != null)
                    event.stopPropagation();
            };

        return instance;
    }

    this.createNewElement = function(name, container) {
        var element = document.createElement("div");
        element.classList.add(name);
        element.classList.add("Component");
        element.dataset.Name = name;
        element.dataset.Container = container;

        return element;
    }

    this.determineElements = function() {
        this.properties = new DomQuery(this.element).getChild(WithClass("Properties"));

        if (this.properties !== null)
            this.form = new DomQuery(this.properties).getChild(WithTagName("FORM"));
        else
            this.form = null;

        this.captionElement = new DomQuery(this.element).getChild(WithClass("Caption"));

        if (!this.editable && this.form !== null) {
            for (var index = 0; index < this.form.elements.length; index++) {
                this.form.elements[index].readOnly = true;
                this.form.elements[index].disabled = true;
            }

            var fields = new DomQuery(this.form).getDescendants(WithClass("edit"));

            for (var index = 0; index < fields.length; index++) {
                var field = fields[index];

                field.classList.remove("edit");
                field.classList.add("display");
            }
        }
    }

    this.name = this.element.dataset.Name;
    this.container = this.element.dataset.Container == "true";
    this.canFocus = true;
    this.closable = true;
    this.editable = editable;

    this.determineElements();
}

function FormDocumentComponent(element) {
    FormBuilderComponent.call(this, element);

    this.createElement = function() {
        return this.element.cloneNode();
    }

    this.createFormBuilderElement = function(editor, value) {
        var element = this.createElement();
        var instance = new InlineFormBuilderElement(element, value, this);

        return instance;
    }
}

function FormFieldComponent(element, editable) {
    FormBuilderComponent.call(this, element, editable);

    this.createFormBuilderElement = function(editor, value) {
        var element = new DefaultFormBuilderElement(this.createElement(), value, this);
        var caption = new DomQuery(value).getChild(WithTagName("Caption"));

        if (caption === null)
            caption = editor.xmlDocument.createElement("Caption");

        var caption_ = this.caption.createInstance(editor, caption);

        var labels = new DomQuery(value).getChild(WithTagName("Labels"));

        if (labels === null)
            labels = editor.xmlDocument.createElement("Labels");

        var labels_ = this.labels.createInstance(editor, labels);

        element.appendChild(caption_);
        element.appendChild(labels_);
        return element;
    }

    this.caption = new FormDocumentComponent(this.createNewElement("Caption", false), false);
    this.caption.canFocus = false;
    this.caption.closable = false;

    this.labels = new FormDocumentComponent(this.createNewElement("Labels", false), false);
    this.labels.canFocus = false;
    this.labels.closable = false;
}

function FormEmbedComponent(element, editable) {
    FormBuilderComponent.call(this, element, editable);

    this.createFormBuilderElement = function(editor, value) {
        var element = this.createElement();
        var instance = new EmbedFormBuilderElement(element, value, this);

        return instance;
    }

    this.canFocus = true;
}

function FormSectionComponent(element, editable) {
    FormBuilderComponent.call(this, element, editable);

    this.createFormBuilderElement = function(editor, value) {
        var element = new DefaultFormBuilderElement(this.createElement(), value, this);

        var title = new DomQuery(value).getChild(WithTagName("Title"));
        var contents = new DomQuery(value).getChild(WithTagName("Contents"));

        if (title == undefined) {
            title = editor.xmlDocument.createElement("Title");
            contents = editor.xmlDocument.createElement("Contents");
        }

        var target = this.contents.createInstance(editor, contents);
        var title_ = this.title.createInstance(editor, title);

        element.appendChild(title_);
        element.appendChild(target);
        element.target = target;

        return element;
    }

    this.title = new FormDocumentComponent(this.createNewElement("Title", false), false);
    this.title.canFocus = false;
    this.title.closable = false;

    this.contents = new FormBuilderComponent(this.createNewElement("Contents", true), false);
    this.contents.closable = false;
    this.contents.canFocus = false;

    this.canFocus = true;
}

function EditorType() {
    this.canFocus = true;
    this.container = true;
}

function Editor(element, object, name) {
    WebPageComponent.call(this, element);

    this._contains = function(object, element) {
        var result = false;

        for (var index = 0; index < this.object.childNodes.length; index++)
            result = result || (this.object.childNodes[index] === element);

        return result;
    }

    this.appendChild = function(element) {
        this.element.appendChild(element.element);

        if (!this._contains(this.object, element.object))
            this.object.appendChild(element.object);

        element.parent = this;
        element.readFromElement();
    }

    this.blur = function() {
        this.element.classList.remove("Target");
    }

    this.canFocus = function() {
        return true;
    }

    this.clear = function() {
        var components = new DomQuery(this.element).getChildren(WithClass("Component"));

        for (var index = 0; index < components.length; index++)
            this.element.removeChild(components[index]);
    }

    this.focus = function() {
        this.element.focus();
        this.element.classList.add("Target");
    }

    this.writeToElement = function() {
        for (var index = 0; index < this.element.childNodes.length; index++) {
            var child = this.element.childNodes[index];

            if (child.component != undefined)
                child.component.writeToElement();
        }
    }

    this.object = object;
    this.target = this;
    this.type = new EditorType();
    this.name = name;
    this.container = true;
    this.element.component = this;
}

function FormBuilderElement(element, object, type) {
    WebPageComponent.call(this, element);

    this._contains = function(object, element) {
        var result = false;

        for (var index = 0; index < this.object.childNodes.length; index++)
            result = result || (this.object.childNodes[index] === element);

        return result;
    }

    this.after = function(element) {
        this.element.after(element.element);

        if (!this._contains(this.object.parentNode, element.object))
            this.object.after(element.object);

        element.parent = this.parent;
        element.readFromElement();
    }

    this.appendChild = function(element) {
        this.element.appendChild(element.element);

        if (!this._contains(this.object, element.object))
            this.object.appendChild(element.object);

        element.parent = this;
        element.readFromElement();
    }

    this.attachHandlers = function() {
        var object = this;

        if (this.closeButton != undefined) {
            this.closeButton.onclick = function(event) {object.close(); event.stopPropagation(); };
            this.moveDownButton.onclick = function(event) {object.moveDown(); event.stopPropagation(); };
            this.moveUpButton.onclick = function(event) {object.moveUp(); event.stopPropagation(); };
        }
    }

    this.attributeToBoolean = function (value) {
        return value === "True";
    }

    this.before = function(element) {
        this.element.before(element.element);

        if (!this._contains(this.object.parentNode, element.object))
            this.object.before(element.object);

        element.parent = this.parent;
        element.readFromElement();
    }

    this.booleanToAttribute = function (value) {
        if (value)
            return "True";
        else
            return "False";
    }

    this.blur = function() {
        this.element.classList.remove("Target");
        this.writeToElement();
        this.updateHeader();
    }

    this.canFocus = function() {
        return this.type.canFocus;
    }

    this.close = function() {
        this.remove();
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.header = query.getChild(WithClass("Header"));

        if (this.header != undefined)
            this.title = this.header.childNodes[1];

        this.closeButton = query.getChild(WithClass("Close"));
        this.moveDownButton = query.getChild(WithClass("Down"));
        this.moveUpButton = query.getChild(WithClass("Up"));

        this.form = query.getChild(WithClass("Properties"));

        this.fields = new Array();

        if (this.form != undefined) {
            var formQuery = new DomQuery(this.form);

            Array.prototype.push.apply(this.fields, formQuery.getDescendants(WithTagName("INPUT")));
            Array.prototype.push.apply(this.fields, formQuery.getDescendants(WithTagName("TEXTAREA")));

            object = this;

            this.fields = this.fields.filter(function (field, index) {
                var formId = object.form.childNodes[0].id;
                return (field.name !== "form") && (field.name.startsWith(formId) || field.name === "Type")
            });
        }

        for (var index = 0; index < this.fields.length; index++) {
            var fieldName = this.fields[index].name.split("/").pop();

            if (fieldName === "FieldType")
                this.fields[index].readOnly = true;
        }
    }

    this.focus = function() {
        this.element.focus();
        this.element.classList.add("Target");
    }

    this.getName = function() {
        var field = this.getNameField();

        if (field != null)
            return field.value;
        else
            return "";
    }

    this.getNameField = function() {
        var result = null;

        for (var index = 0; index < this.fields.length; index++) {
            var fieldName = this.fields[index].name.split("/").pop();

            if (fieldName == "Name")
                result = this.fields[index];
        }

        return result;
    }

    this.moveDown = function() {
        if (this.element.nextSibling !== null && this.element.nextSibling.component !== undefined) {
            var next = this.element.nextSibling.component;

            this.remove();
            next.after(this);
            this.element.onclick();
        }
    }

    this.moveUp = function() {
        if (this.element.previousSibling.component !== undefined) {
            var previous = this.element.previousSibling.component;

            this.remove();
            previous.before(this);
            this.element.onclick();
        }
    }

    this.readField = function (fieldName) {
        return this.object.getAttribute(fieldName);
    }

    this.remove = function() {
        this.parent.element.removeChild(this.element);
        this.parent.object.removeChild(this.object);

        if (this.parent.type.canFocus) {
            this.parent.element.focus();
            this.parent.element.onclick(null);
        }
        else {
            this.parent.parent.element.focus();
            this.parent.parent.element.onclick(null);
        }
    }

    this.updateHeader = function() {
        if (this.header != undefined) {
            var name = this.getName();

            if (name.length > 0)
                this.header.childNodes[1].innerHTML = name;
            else
                this.header.childNodes[1].innerHTML = this.title.innerHTML;
        }
    }

    this.writeField = function (fieldName, value) {
        this.object.setAttribute(fieldName, value);
    }

    this.object = object;
    this.type = type;
    this.container = this.type.container;
    this.target = this;
    this.element.component = this;

    this.determineElements();
    this.attachHandlers();
}


function DefaultFormBuilderElement(element, object, type) {
    FormBuilderElement.call(this, element, object, type);

    this.readFromElement = function() {
        for (var index = 0; index < this.fields.length; index ++) {
            var fieldName = this.fields[index].name.split("/").pop();
            var field = this.fields[index];

            if (fieldName === "Optional")
                field.checked = this.attributeToBoolean(this.readField(fieldName));
            else {
                if (this.object.hasAttribute(fieldName)) {

                    if (field.type === "checkbox")
                        field.checked = this.attributeToBoolean(this.readField(fieldName));
                    else
                        field.value = this.readField(fieldName);
                }
            }

            if (field.onchange != undefined)
                field.onchange();
        }

        this.updateHeader();
    }

    this.writeToElement = function() {
        for (var index = 0; index < this.fields.length; index ++) {
            var fieldName = this.fields[index].name.split("/").pop();

            if (fieldName === "Optional")
                this.writeField(fieldName, this.booleanToAttribute(this.fields[index].checked));
            else if (fieldName !== "Label" && fieldName !== "Contents") {
                if (this.fields[index].type == "checkbox")
                    this.writeField(fieldName, this.booleanToAttribute(this.fields[index].checked));
                else if (this.fields[index].value !== "")
                    this.writeField(fieldName, this.fields[index].value);
                else
                    this.object.removeAttribute(fieldName);
            }
        }

        for (var index = 0; index < this.element.childNodes.length; index++) {
            var child = this.element.childNodes[index];

            if (child.component != undefined)
                child.component.writeToElement();
        }
    }
}

function EmbedFormBuilderElement(element, object, type) {
    FormBuilderElement.call(this, element, object, type);

    this.readFromElement = function() {
        var field = this.fields[0];

        field.value = this.object.innerHTML;
    }

    this.writeToElement = function() {
        var field = this.fields[0];

        this.object.innerHTML = field.value;
    }
}

function InlineFormBuilderElement(element, object, type, target) {
    FormBuilderElement.call(this, element, object, type, target);

    this.getCaptionElement = function() {
        var fields = new DomQuery(this.parent.form).getDescendants(WithTagName("TEXTAREA"))
        var result = null;

        for (var index = 0; index < fields.length; index++) {
            var fieldName = fields[index].name.split("/").pop();

            if (fieldName === "Label")
                result = fields[index];
        }

        return result;
    }

    this.getTypeElement = function() {
        var result = null;

        if (this.parent.form !== null)
            result = new DomQuery(this.parent.form).getDescendant(WithClass("TypeComponent"));

        return result;
    }

    this.readFromElement = function() {
        if (this.object.tagName === "Labels") {
            var typeField = this.getTypeElement();

            if (typeField.component !== undefined) {
                var labels = new Array();

                for (var index = 0; index < this.object.childNodes.length; index++) {
                    var label = this.object.childNodes[index];

                    labels.push({
                        "Name": label.getAttribute("Name"),
                        "Value": label.innerHTML
                    });
                }

                typeField.component.setLabels(labels);
            }
        }
        else if (this.object.childNodes.length > 0)
            this.getCaptionElement().value = new XMLSerializer().serializeToString(this.object.childNodes[0]);
    }

    this.writeToElement = function() {
        if (this.object.tagName === "Labels") {
            var typeField = this.getTypeElement();

            if (typeField.component !== undefined) {
                var xmlDocument = document.implementation.createDocument("", "", null);
                var labelsElement = xmlDocument.createElement("Labels");
                var labels = typeField.component.getLabels();

                for (var index = 0; index < labels.length; index++) {
                    var label = xmlDocument.createElement("Label");
                    label.setAttribute("Name", labels[index].Name);
                    label.innerHTML = labels[index].Value;

                    labelsElement.appendChild(label);
                }

                this.object.innerHTML = labelsElement.innerHTML;
            }
        }
        else
            this.object.innerHTML = this.getCaptionElement().value;
    }
}

function Progress(element) {
    this.build = function() {
        this.progress = document.createElement("div");
        this.progress.classList.add("Value");
        this.progress.style.width = "0%";

        this.element.appendChild(this.progress);
    }

    this.setProgress = function(value) {
        if (this.timeOutId !== 0)
            window.clearTimeout(this.timeOutId);

        this.progress.style.width = value + "%";
        this.progress.style.visibility = "visible";

        var object = this;

        if (value < 100 && value > 0) {
            var remainder = 100 - value;
            var timeOut = 1000 / remainder;

            this.timeOutId = window.setTimeout(function() {object.timeOutId = 0; object.setProgress(value + remainder / 25);}, timeOut);
        }
        else if (value === 100) {
            window.setTimeout(function() { object.setProgress(0); object.progress.style.visibility = "hidden"; }, 100);
        }
    }

    this.timeOutId = 0;
    this.element = element;
    this.build();
}

interactivityRegistration.register("FormBuilder", function (element) { return new FormBuilder(element); });
