function FormSection(element) {
    WebPageComponent.call(this, element);

    this.focus = function () {
        var target = this.childComponents.find(child => child.isFocusable());

        if (target !== undefined)
            target.focus();
    }

    this.setUp = function () {
        for (var index = 0; index < this.element.tBodies[0].rows.length; index++) {
            var row = this.element.tBodies[0].rows[index];
            var cell = row.cells[1];
            var element = cell.firstChild;

            if (element !== null && element.component !== undefined)
                this.updateRowVisibility(row, element.component);
        }
    }

    this.getRowWithComponent = function (component) {
        var result = null;
        var index = 0;

        while (result === null && index < this.element.tBodies[0].rows.length) {
            var row = this.element.tBodies[0].rows[index];

            if (row.contains(component.element))
                result = row;
            else
                index++;
        }

        return result;
    }

    this.updateRowVisibility = function (row, component) {
        var visibility = new HtmlClassSwitch(row, "Hidden");
        visibility.setStatus(!component.visible);
    }

    this.handleEvent = function (event) {
        if (event instanceof VisibilityChangedEvent) {
            var row = this.getRowWithComponent(event.field);

            if (row !== null)
                this.updateRowVisibility(row, event.field);
        }
    }

    this.setUp();
}

interactivityRegistration.register("FormSection", function (element) { return new FormSection(element); });
