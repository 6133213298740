function MoneyField(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        if (this.inputField != null) {
            this.inputField.onfocus = function(event) { object.element.classList.add("Focus")};
            this.inputField.onblur = function(event) {object.element.classList.remove("Focus")};
        }
    }

    this.determineElements = function() {
        this.textField = new DomQuery(this.element).getChild(WithClass("SingleLineTextField"));

        if (this.textField !== null)
            this.inputField = new DomQuery(this.textField).getChild(WithTagName("INPUT"));
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("Money", function (element) { return new MoneyField(element); });

