function Tristate(element) {
    this.attachHandlers = function() {
        var object = this;

        this.disabled.onclick = function(event) {object.toggle("Disabled")};
        this.neutral.onclick = function(event) {object.toggle("Neutral")};
        this.enabled.onclick = function(event) {object.toggle("Enabled")};
    }

    this.initialize = function() {
        this.disabled = document.createElement("div");
        this.disabled.classList.add("Disabled");

        this.neutral = document.createElement("div");
        this.neutral.classList.add("Neutral");

        this.enabled = document.createElement("div");
        this.enabled.classList.add("Enabled");

        this.element.appendChild(this.disabled);
        this.element.appendChild(this.neutral);
        this.element.appendChild(this.enabled);

        this.effect = true;
        this.effectClass = new HtmlClassSwitch(this.element, "Visible");
        this.effectClass.setStatus(true);
    }

    this.setEffect = function(effect) {
        this.effect = effect;
        this.effectClass.setStatus(effect);
    }

    this.toggle = function(state) {
        this.element.classList.remove("Disabled");
        this.element.classList.remove("Neutral");
        this.element.classList.remove("Enabled");

        this.element.classList.add(state);
        this.state = state;

        if (this.element.onchange !== null)
            this.element.onchange();
    }

    this.element = element;

    this.initialize();
    this.attachHandlers();
    this.toggle(this.element.dataset.Value);
}