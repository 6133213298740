function Tab(index, element) {
    this.determineState = function () {
       this.selected = new HtmlClassSwitch(this.element, "Selected").getStatus();
       this.add = new HtmlClassSwitch(this.element, "Add").getStatus();
    }

    this.index = index;
    this.element = element;
    this.selected = false;
    this.add = false;

    this.determineState();
}

function TabMenu(element) {
    WebPageComponent.call(this, element);

    this.determineTabs = function () {
        var query = new DomQuery(this.element);

        var tabElements = query.getChildren(WithTagName("LI"));

        for (var index = 0; index < tabElements.length; index++) {
            var tab = new Tab(index, tabElements[index]);
            this.tabs.push(tab);

            if (tab.add)
                this.hasAdd = true;
        }
    };

    this.addComponents = function () {
        if (this.hasAdd) {
            var object = this;
            var expand = document.createElement("li");

            expand.classList.add("Expand");
            expand.onclick = function (event) {
                object.toggleAdd();
            }

            this.element.appendChild(expand, this.element);
        }
    }

    this.toggleAdd = function () {
        this.expanded.toggle();
    }

    this.tabs = new Array();
    this.expanded = new HtmlClassSwitch(this.element, "Expanded");
    this.hasAdd = false;
    this.determineTabs();
    this.addComponents();
}

interactivityRegistration.register("TabMenu", function (element) { return new TabMenu(element); });
