function UploadRow(element, file) {
    this.build = function() {
        this.fileNameCell = this.element.appendChild(document.createElement("td"));
        this.fileNameCell.className = "FileName";
        this.fileNameCell.innerHTML = this.file.name;

        this.progressBar = new ProgressBar(1);
        this.progressBarCell = this.element.appendChild(document.createElement("td"));
        this.progressBarCell.appendChild(this.progressBar.element);

        this.actionCell = this.element.appendChild(document.createElement("td"));
    }

    this.cancel = function() {
        this.aborted = true;
        this.button.disabled = true;
        this.button.innerHTML = "Cancelled";
        this.element.classList.add("Cancelled");
    }

    this.createCancelButton = function(cancel) {
        this.button = document.createElement("button");
        this.button.innerHTML = "Cancel";
        this.button.className = "button";
        this.button.addEventListener(
            "click",
            (event) => {
                this.cancel();
                return false;
            }
        );

        this.actionCell.appendChild(this.button);
    }

    this.setProgress = function(progress, phase) {
        this.progressBar.setProgress(progress, phase);
    }

    this.element = element;
    this.file = file;

    this.aborted = false;
    this.build();
}

function UploadArea(element) {
    FileDropZone.call(this, element);

    this.buildToolbar = function() {
        const span = document.createElement("span");
        span.innerText = "Drag files here to upload"

        const file = document.createElement("input");
        file.type = "file";

        const label = document.createElement("label");
        label.appendChild(file);
        label.appendChild(document.createTextNode("Browse"));

        const toolbar = document.createElement("div");
        toolbar.classList.add("Toolbar");
        toolbar.appendChild(span);
        toolbar.appendChild(label);

        return toolbar;
    }

    this.build = function() {
        this.decoratedComponent = this.element.firstChild.component;
        const decoratedElement = this.element.firstChild;

        this.toolbar = this.buildToolbar();
        this.element.prepend(this.toolbar);

        this.fileInput = new DomQuery(this.toolbar).getDescendant(WithTagName("INPUT"));
        this.fileInput.multiple = true;
        this.fileInput.accept = this.element.dataset.Accept;
        this.fileInput.addEventListener(
            "input",
            (event) => {
                this.handleFiles(this.fileInput.files);
            }
        );

        this.uploadImage = this.element.insertBefore(document.createElement("div"), decoratedElement);
        this.uploadImage.className = "UploadImage";

        this.progressArea = this.element.insertBefore(document.createElement("div"), decoratedElement);
        this.progressArea.className = "ProgressArea";

        this.messages = this.element.insertBefore(document.createElement("ul"), decoratedElement);

        this.hidden = new HtmlClassSwitch(this.progressArea, "Hidden");
        this.hidden.setStatus(true);

        this.progressTable = this.progressArea.appendChild(document.createElement("table"));
        this.progressTable.className = "data";
        this.progressTable.appendChild(this.createTableHeaderRow());
    }

    this.createFile = function(file, uploadRow, callback) {
        const request = new XMLHttpRequest();
        request.open("POST", this.URI);

        const formData = new FormData();
        formData.append("form", "Form");
        formData.append("FileName", file.name);
        formData.append("FileSize", file.size);

        request.onreadystatechange = (event) => {
            if (request.readyState === 4 && request.status === 201) {
                uploadRow.location = request.getResponseHeader("location");
                this.processFile(file, uploadRow, callback);
            }
        }
        request.send(formData);
    }

    this.createFinalCallbackFunction = function(uploadRow, callback) {
        return () => {
            this.decoratedComponent.refresh();
            distributeEvent(new DataChangedEvent(this));

            if (!uploadRow.aborted) {
                uploadRow.button.disabled = true;
                uploadRow.button.innerHTML = "Finished";
                uploadRow.element.classList.add("Finished");
            }

            if (callback !== null)
                callback();
        }
    }

    this.createProcessFileFunction = function(file, uploadRow, callback) {
        return () => {
            this.createFile(file, uploadRow, callback);
        };
    }

    this.createProcessFilePartFunction = function(part, uploadRow, callback) {
        return () => {
            this.processFilePart(part, uploadRow, callback);
        };
    }

    this.createTableHeaderRow = function() {
        const row = document.createElement("tr");

        const fileNameHeader = row.appendChild(document.createElement("th"));
        fileNameHeader.innerHTML = "File";

        const progressBarHeader = row.appendChild(document.createElement("th"));
        progressBarHeader.innerHTML = "Progress";

        const cancelButtonHeader = row.appendChild(document.createElement("th"));
        cancelButtonHeader.innerHTML = "Action";

        return row;
    }

    this.createUploadRow = function(file) {
        const element = this.progressTable.appendChild(document.createElement("tr"));
        element.className = "UploadRow";

        return new UploadRow(element, file);
    }

    this.handleEvent = function(event) {
        if (event instanceof FileUploadStatusChangedEvent && event.upload.target === this.URI && event.upload.state === State.Successful) {
            this.decoratedComponent.refresh();
            distributeEvent(new DataChangedEvent(this));
        }
    }

    this.handleFiles = function(files) {
        if (this.shouldHandlePartitioned(files) || !('serviceWorker' in navigator))
            this.processFiles(files);
        else
            this.registerFiles(files);
    }

    this.shouldHandlePartitioned = function(files) {
        let result = this.allowPartitioning;

        if (result) {
            let index = 0;

            while (!result && index < files.length) {
                result = files[index].size > this.partSize;
                index++;
            }
        }

        return result;
    }

    this.initialize = function() {
        this.URI = this.element.dataset.Uri;
        this.maximumSize = parseInt(this.element.dataset.MaximumSize);
        this.allowPartitioning = this.element.dataset.AllowPartition === "true";

        if (this.allowPartitioning)
            this.partSize = parseInt(this.element.dataset.PartSize);
        else
            this.partSize = this.maximumSize;

        this.initializeDropZone();
    }

    this.processFilePart = function(part, uploadRow, callback) {
        if (!uploadRow.aborted) {
            const formData = new FormData();
            formData.append("form", "Form");
            formData.append("FileName", part.name);
            formData.append("Index", part.index);
            formData.append("Contents", part.blob);

            const request = new XMLHttpRequest();
            request.open("POST", uploadRow.location);

            request.upload.onprogress = (event) => {
                if (uploadRow.aborted)
                    request.abort();

                if (event.lengthComputable) {
                    const progress = (part.offset + part.blob.size * event.loaded / event.total) / uploadRow.file.size;
                    uploadRow.setProgress([progress * 100], 0);
                }
            };

            request.onreadystatechange = (event) => {
                if (request.readyState === 4 && callback !== null)
                    callback();
            }

            request.send(formData);
        }
        else if (callback !== null)
            callback();
    }

    this.processFile = function(file, uploadRow, callback) {
        const reader = new FileReader();

        reader.onload = (event) => {
            const bytes = new Uint8Array(event.target.result);

            uploadRow.createCancelButton();

            const parts = new Array();
            let offset = 0;
            let remainder = file.size;

            while (remainder > 0) {
                const partSize = Math.min(remainder, this.partSize);
                parts.push({ "offset": offset, "size": partSize });

                offset += partSize;
                remainder = file.size - offset;
            }

            let callback_ = this.createFinalCallbackFunction(uploadRow, callback);

            for (let index = parts.length - 1; index >= 0; index--) {
                const part = parts[index];
                part.blob = new Blob([bytes.subarray(part.offset, part.offset + part.size)]);
                part.name = file.name;
                part.index = index;

                callback_ = this.createProcessFilePartFunction(part, uploadRow, callback_);
            }

            if (callback_ !== null)
                callback_();
        };

        reader.readAsArrayBuffer(file);
    }

    this.registerFile = function(file) {
        return new Promise((resolve, reject) => {
            if (!this.validateFile(file, this.element.dataset.Accept)) {
                this.fileStore.add({
                    target: this.URI,
                    name: file.name,
                    contents: null,
                    size: file.size,
                    state: State.Forbidden,
                    attempts: 0,
                    progress: 0,
                    message: "Unsupported file type",
                    location: null
                });

                resolve();
            }
            else if (file.size > this.maximumSize) {
                this.fileStore.add({
                    target: this.URI,
                    name: file.name,
                    contents: null,
                    size: file.size,
                    state: State.Forbidden,
                    attempts: 0,
                    progress: 0,
                    message: "Cannot upload file. File size over " + this.maximumSize / (1000 * 1000) + "MB",
                    location: null
                });

                resolve();
            }
            else {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.fileStore.add({
                        target: this.URI,
                        name: file.name,
                        contents: new Uint8Array(event.target.result),
                        size: file.size,
                        state: State.Initial,
                        attempts: 0,
                        progress: 0,
                        message: "",
                        location: null
                    });

                    resolve();
                };
                reader.onerror = (event) => {
                    resolve();
                }
                reader.onabort = (event) => {
                    resolve();
                }

                reader.readAsArrayBuffer(file);
            }
        });
    }

    this.registerFiles = function(files) {
        let task = new Promise(
            (resolve, reject) => {
                this.fileInput.disabled = true;
                resolve();
            }
        );

        for (const file of files)
            task = task.then(() => this.registerFile(file));

        task = task.then(() => {
            this.fileInput.value = null;
            this.fileInput.disabled = false;
        });
    }

    this.processFiles = function(files) {
        const uploadRows = new Array();

        for (let i = 0; i < files.length; i++)
            uploadRows.push(this.createUploadRow(files[i]));

        const listener = (event) => {
            event.preventDefault();
            return event.returnValue = "Are you sure you want to exit?";
        }

        window.addEventListener("beforeunload", listener);
        let callback = () => { window.removeEventListener("beforeunload", listener) };

        for (let index = files.length - 1; index >= 0; index--) {
            const file = files[index];
            const uploadRow = uploadRows[index];

            if (!this.validateFile(file, this.element.dataset.Accept))
                uploadRow.progressBarCell.innerHTML = "Unsupported file type";
            else if (file.size > this.maximumSize)
                uploadRow.progressBarCell.innerHTML = "Cannot upload file. File size over " + this.maximumSize / (1000 * 1000) + "MB";
            else
                callback = this.createProcessFileFunction(file, uploadRow, callback);
        }

        if (callback !== null)
            callback();
    }

    this.fileStore = new FileStore();

    this.build();
    this.initialize();
}

interactivityRegistration.register("UploadArea", function(element) { return new UploadArea(element); });
