function BiStateField (element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.valueField = query.getChild(WithTagName("INPUT"));
        this.trueSelector = query.getChild(WithClass("TrueSelector"));
        this.falseSelector = query.getChild(WithClass("FalseSelector"));

        this.falseState = new HtmlClassSwitch(this.element, "False");
        this.falseState.setStatus(this.valueField.value == "False");

        this.trueState = new HtmlClassSwitch(this.element, "True");
        this.trueState.setStatus(this.valueField.value == "True");
    }

    this.biStateClicked = function (event, biState) {
        this.trueState.setStatus(biState);
        this.falseState.setStatus(!biState);

        if (biState)
            this.valueField.value = "True"
        else
            this.valueField.value = "False";
    }

    this.attachEventHandlers = function () {
        if (this.mode === ControlMode.edit) {
            var object = this;

            this.trueSelector.onclick = function (event) { object.biStateClicked(getEvent(event), true); };
            this.falseSelector.onclick = function (event) { object.biStateClicked(getEvent(event), false); };
        }
    }

    this.determineElements();
    this.attachEventHandlers();
}

interactivityRegistration.register("BiState", function (element) { return new BiStateField(element); });